import React from 'react'
import Layout from '../../layouts/layout'

class GettingStarted extends React.Component {
  render () {
    return (
<Layout>
        <header className="masthead getting_started">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 col-md-10 mx-auto">
                <div className="page-heading">
                  <h1>Getting Started With NLP</h1>
                  <span className="subheading">Follow this guide to get a good beginner's foothold with NLP!</span>
                </div>
              </div>
            </div>
          </div>
        </header>
        {/* Main Content */}
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-10 mx-auto">
              <p>As a very first step, we recommend all readers of this blog to get started with the NLTK - Natural Language Toolkit for Python - <a href="http://www.nltk.org/book/" target="_blank" rel="noreferrer">book</a>. This library is extremely user friendly and makes it very easy to implement NLP algorithms. If you already have Python experience, you could get started writing simple algorithms in just a few minutes!</p>
              <p>All of us at NLP Champs have read the entirity of this book, most of the posters on this site have read the entirity of it, and so we recommend that you do too!</p>
              <p>It's a bonus if you have experience using the Stanford NLP, OpenNLP, or ClearNLP libraries.</p>
              <p>Still hungry for more? Check out <a href="https://github.com/keon/awesome-nlp">this NLP resources list on github</a> to find even MOAR resources!</p>
            </div>
          </div>
        </div>
</Layout>
    );
  }
}

export default GettingStarted;
